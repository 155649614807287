/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://jrw22473tvhgtkylmo6mnampfa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-avpodldgjjar3n4dkmrea7hh5a",
    "aws_cognito_identity_pool_id": "us-east-1:a1de7dca-5bcf-481d-818f-8db42f2a4300",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_rI2LJ7Nn0",
    "aws_user_pools_web_client_id": "1qjnbdg8df1iuanaibrf3mc9hb",
    "oauth": {}
};


export default awsmobile;
